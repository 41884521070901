import { navigate } from "gatsby";
import React, { useEffect } from "react";

const Index = () => {
  useEffect(() => {
    navigate("/purchase-orders/pending");
  }, []);
  return <></>;
};

export default Index;
